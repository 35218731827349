import React, { FC, memo } from "react"
import { RootStyled } from "./styles"

const _score = [{
  login: "admin",

}]

export const AllTimeScore: FC = memo(() => {
  return <RootStyled>affa</RootStyled>
})
